import {
  ChakraProvider,
  theme,
} from "@chakra-ui/react"
import { HomePage } from "./pages/Home"
import { PrivacyPolicyPage } from "./pages/PrivacyPolicyPage";
import { DisclaimerPage } from "./pages/DisclaimerPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FaqPage } from "./pages/FaqPage"
import ArticleList from "./pages/BlogPage"
import Blog1 from "./pages/Blog1"

export const App = () => (
  <ChakraProvider theme={theme}>
    {/* <Navbar /> */}
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/disclaimer" element={<DisclaimerPage />} />
      <Route path="/faqs" element={<FaqPage />} />
      <Route path="/blog" element={<ArticleList />} />
      <Route path="/blog1" element={<Blog1 />} />
    </Routes>
    </BrowserRouter>
  </ChakraProvider>
)
